import * as Sentry from "@sentry/sveltekit";

Sentry.init({
    dsn: "https://8b8c3b731f78d1a3756b9c3af631a4fc@o4507092506771456.ingest.de.sentry.io/4507776931070032",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [Sentry.replayIntegration()]
})

export const handleError = Sentry.handleErrorWithSentry();